<template>
  <v-app>
    <v-main class="fullBg">
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="6">
            <v-card class="elevation-12" v-if="!loading">
              <v-alert tile class="mt-5" :type="error ? 'error' : 'success'">{{
                message
              }}</v-alert>
              <v-flex class="text-center" pb-3>
                <v-btn color="primary" @click="close">{{ $t("Close") }}</v-btn>
              </v-flex>
            </v-card>
            <v-progress-linear
              v-else
              color="deep-purple accent-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      message: "",
      error: false,
      loading: true,
    };
  },
  mounted() {
    this.post("/bankauthorize/" + this.$route.params.bank, {
      code: this.$route.query.code,
      bank: this.$route.query.bank,
      state: this.$route.query.state,
    })
      .then((data) => {
        this.message = this.$t("bank-validation." + data.message);
        this.loading = false;
      })
      .catch((res) => {
        this.error = true;
        this.message = this.$t("bank-validation." + res.message);
        this.loading = false;
      });
    window.onbeforeunload = () => {
      window.opener.postMessage(
        {
          id: "bank_activation",
          bank: this.$route.query.bank,
          error: this.error,
        },
        document.location.origin
      );
    };
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`,
      };
    },
  },
  methods: {
    close() {
      window.opener.postMessage(
        {
          id: "bank_activation",
          bank: this.$route.query.bank,
          error: this.error,
        },
        document.location.origin
      );
      window.close();
    },
  },
};
</script>

<style scoped>
.fullBg {
  background-image: url("~@/assets/login.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
